import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'

export default function Article({ data, location }) {
  const post = data.mdx
  const image = data.mdx.frontmatter.image ? (
    <GatsbyImage
      image={getImage(data.mdx.frontmatter.image)}
      alt={`image of ${post.frontmatter.title}`}
    ></GatsbyImage>
  ) : null
  const ogImage = data.mdx.frontmatter.image ? getSrc(data.mdx.frontmatter.image): ''
  return (
    <div>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        keyword={post.frontmatter.keyword}
        pathName={location.pathname}
        ogImage={ogImage}
        ogType='article'
      />
      <Layout>
        <h1>{post.frontmatter.title}</h1>
        <p>{post.frontmatter.description}</p>
        <MDXRenderer frontmatter={post.frontmatter} image={image}>
          {post.body}
        </MDXRenderer>
      </Layout>
    </div>
  )
}
export const query = graphql`
  query ($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        description
        keyword
        image {
          childImageSharp {
            gatsbyImageData(width: 800, height: 300)
            original {
              src
            }
          }
        }
      }
    }
  }
`
